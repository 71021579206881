import React from "react";
import { FaBars } from "react-icons/fa";
import imgProfile from "../../Recruiter/assets/img/perfil2.jpg";

import FormRecruiter from "./Form/FormRecruiter";
import Softskills from "../../Recruiter/SoftSkills/Form/SoftSkills";

// import Softskills from "../../Recruiter/SoftSkills/SoftSkills";
export const ProfileCandidates = () => {
  // console.log('ProfileCandidates:...');
  return (
    <>
      <FormRecruiter />
    </>
  );
};
export default ProfileCandidates;
